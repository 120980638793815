import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatarR.png";
import Tilt from "react-parallax-tilt";
import Particle from "../Particle";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
          <Particle />
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              CONOCE <span className="purple"> QUIÉN SOY </span>
            </h1>
            <p className="home-about-body">
              Soy un Desarrollador Full Stack apasionado por la tecnología y el
              desarrollo de software. Me dedico a optimizar operaciones y
              procesos empresariales mediante el{" "}
              <i>
                <b className="purple">desarrollo</b>
              </i>{" "}
              de sistemas y aplicaciones móviles innovadoras.
              <br />
              <br />
              Mi objetivo es contribuir al éxito empresarial y a la
              transformación digital en un entorno desafiante, siempre buscando
              oportunidades de aprendizaje y crecimiento profesional continuo.
              Creo firmemente en el poder de la tecnología para cambiar el mundo
              y estoy comprometido a aprender cada día más.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
