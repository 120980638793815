import React from "react";
import { Container} from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Conjunto de <strong className="purple">Habilidades </strong>Profesionales
        </h1>
        <Techstack />
        <h1 className="project-heading">
          Herramientas que <strong className="purple">Utilizo</strong>
        </h1>
        <Toolstack />
      </Container>
    </Container>
  );
}

export default About;
