import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import cargoexx from "../../Assets/Projects/cargoexx.png";
import mobile from "../../Assets/Projects/mobile.png";
import munsac from "../../Assets/Projects/munsac.png";
import cccs from "../../Assets/Projects/cccs.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mis Trabajos <strong className="purple">Recientes</strong>
        </h1>
        <p style={{ color: "white" }}>
          Aquí hay algunos proyectos en los que he trabajado recientemente.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={cargoexx}
              isBlog={false}
              title="Sistema de gestión"
              description="Diseñé y ejecuté soluciones de software clave, incluido un
              sistema de seguimiento de envíos completo con diferentes
              secciones que solucionan lógica de negocio a nivel empresarial.
              Edición y creación de registros, administrador de usuarios,
              gestión de eventos, etc. Lenguajes utilizados: React, NodeJS."
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={mobile}
              isBlog={false}
              title="App para la gestión de envíos"
              description="Solución móvil en React Native con el backend en NodeJS,
              revolucionando los procesos de retiros y entregas de la
              empresa al reemplazar los métodos manuales basados en papel
              por una solución digital integrada. La aplicación mejora
              significativamente la eficiencia operativa y el soporte al
              cliente al ofrecer sincronización en tiempo real con la base
              de datos, facilitando la gestión de datos de envíos y
              recepciones. Implementé características como escaneo de
              códigos QR, geolocalización para seguimiento en tiempo real,
              y conectividad Bluetooth con impresoras portátiles, lo que
              permite la impresión de etiquetas al instante, mejorando la
              logística de la empresa. Lenguajes utilizados: React Native, NodeJS."
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={munsac}
              isBlog={false}
              title="Módulo de soporte"
              description="Contribuí a nivel front-end utilizando Angular, enfocándome
              en un módulo de soporte al cliente para el seguimiento de
              reparaciones y compras. Mejoré la accesibilidad de la
              información para los usuarios, facilitando una mejor
              experiencia de servicio al cliente. Lenguajes utilizados: Angular."
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={cccs}
              isBlog={false}
              title="Sistema logístico"
              description="Trabajé estrechamente con el backend para implementar
              soluciones logísticas en Angular, enfocándome en optimizar
              operaciones de bodega. Desarrollé funcionalidades para el
              sistema de picking, permitiendo una selección eficiente de
              productos, además de implementar un sistema de tracking que
              mejora la visibilidad del estado de los pedidos, incluyendo
              su ubicación y condición. Contribuí a la reducción de merma
              y a la mejora del seguimiento de pedidos, asegurando una
              gestión más eficaz de los recursos de bodega. Lenguajes utilizados: Angular."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
